<template>
  <div>
    <el-card>
      <div class="title">【{{ title }}】 <span>商盟邀约函内容</span></div>
      <el-form :model="queryInfo" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="邀约函内容" prop="invitationLetterContentsOne">
          <Editorbar v-model="queryInfo.invitationLetterContentsOne" :isClear="isClear"></Editorbar>
          <span class="span">(建议上传文字调整大小和字重，正文字体使用微软雅黑和思源宋体) </span>
        </el-form-item>
        <div class="title">小程序分享链接信息</div>
        <el-form-item label="分享链接标语:">
          <el-input v-model="queryInfo.shareTitleOne" placeholder="商会邀约函" />
        </el-form-item>
        <el-form-item label="分享链接图片:">
          <div class="com_upload">
            <img-big-upload
              :imageShow.sync="queryInfo.sharePicturesOne"
              :addTitleShow="false"
              @removeImg="removeImgDian"
              @uploadOneImgShow="uploadOneImgShowDian"
            />
          </div>
          <div class="img_tips">
            <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
          </div>
        </el-form-item>

        <div class="title" style="margin-top: 100px">
          【湖南总商会&CUC商盟俱乐部】<span>联合商盟邀约函内容</span>
        </div>
        <el-form-item label="邀约函内容" prop="invitationLetterContentsTwo">
          <Editorbar v-model="queryInfo.invitationLetterContentsTwo" :isClear="isClear"></Editorbar>
          <span class="span">(建议上传文字调整大小和字重，正文字体使用微软雅黑和思源宋体) </span>
        </el-form-item>
        <div class="title">小程序分享链接信息</div>
        <el-form-item label="分享链接标语:">
          <el-input v-model="queryInfo.shareTitleTwo" placeholder="商会邀约函" />
        </el-form-item>
        <el-form-item label="分享链接图片:">
          <div class="com_upload">
            <img-big-upload
              :imageShow.sync="queryInfo.sharePicturesTwo"
              :addTitleShow="false"
              @removeImg="removeImgDianTwo"
              @uploadOneImgShow="uploadOneImgShowDianTwo"
            />
          </div>
          <div class="img_tips">
            <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="bottom">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button plain>取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { addInvitation, editInvitation, getInvitation } from '@/api/chambercenter'
const defaultQueryInfo = Object.freeze({
  chamberCommerceId: '',
  createId: '',
  createTime: '',
  invitationLetterContentsOne: '',
  invitationLetterContentsTwo: '',
  sharePicturesOne: '',
  sharePicturesTwo: '',
  shareTitleOne: '',
  shareTitleTwo: '',
  updateId: '',
  updateTime: ''
})
export default {
  name: 'Configure',
  data() {
    return {
      isClear: false,
      rules: {
        invitationLetterContentsOne: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        invitationLetterContentsTwo: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ]
      },
      queryInfo: { ...defaultQueryInfo }
    }
  },
  computed: {
    id() {
      return this.$route.query.id * 1
    },
    chamberCommerceId() {
      return this.$route.query.chamberCommerceId * 1
    },
    title() {
      return this.$route.query.title
    }
  },
  created() {
    if (this.chamberCommerceId) {
      //编辑
      this.getData()
    }
  },
  methods: {
    //点击提交
    submit() {
      this.queryInfo.chamberCommerceId = this.id
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          if (this.chamberCommerceId) {
            //编辑
            const { data: res } = await editInvitation(this.queryInfo)
            if (res.resultCode === 200) {
              this.$message.success('编辑成功')
              this.$router.go(-1)
            }
          } else {
            //新增
            const { data: res } = await addInvitation(this.queryInfo)
            if (res.resultCode === 200) {
              this.$message.success('新增成功')
              this.$router.go(-1)
            }
          }
        }
      })
    },
    //获取长地址图片
    uploadOneImgShowDianTwo(val) {
      this.queryInfo.sharePicturesTwo = val
    },
    /**
     * 删除图
     */
    removeImgDianTwo(val) {
      this.queryInfo.sharePicturesTwo = ''
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.sharePicturesOne = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.sharePicturesOne = ''
    },
    //获取编辑的数据
    async getData() {
      const { data: res } = await getInvitation(this.id)
      if (res.resultCode === 200) {
        this.queryInfo = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.bottom {
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_tips {
  position: relative;
  top: -10px;
  color: #6ba1ff;
}
.title {
  color: #146aff;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;

  span {
    font-weight: 400;
  }
}
.span {
  color: #6ba1ff;
  font-size: 12px;
  font-weight: 400;
}
</style>
